@import '@christies/base.css/global';

.input__holder {
  position: relative;
  pointer-events: all;
  &:focus-within:not(.disabled) {
    .input {
      box-shadow: 0 0 0 0.125rem $color-black;
    }
  }
  &:hover:not(.disabled) {
    .input {
      box-shadow: 0 0 0 0.125rem $color-black;
    }
  }
}

.label {
  background-color: $color-white;
  color: $color-black;
  font-size: 0.75rem;
  left: 0;
  position: absolute;
  margin-left: 1rem;
  z-index: 1;
  top: 0;
  transform: translateY(-50%);
  padding: 0 0.25rem;

  &.disabled {
    cursor: not-allowed;
    color: $color-grey-alpha-40;
  }
}

.optional {
  color: rgba(0, 0, 0, 0.55);
  margin-left: 0.5rem;
}

.input {
  background-color: $color-white;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.0625rem $color-black;
  transition: all $duration-shortest ease-in;
  display: block;
  padding: 1.188rem 1rem;
  width: 100%;
  -webkit-appearance: none;
  &::-ms-reveal,
  &::-ms-clear {
    display: none !important;
  }
  &.password {
    letter-spacing: 0.375rem;
    &__padding {
      padding-right: 3.75rem;
    }
  }
  &.disabled {
    cursor: not-allowed;
    border-color: $color-grey-alpha-40;
    color: $color-grey-alpha-40;

    &,
    &:hover {
      box-shadow: none;
    }
  }
  &:focus {
    outline: none;
  }
}

.input__password {
  position: relative;
  button {
    border: none;
  }
}

.input__clear {
  position: relative;

  & .input__icon > * {
    width: 1.5rem;
    height: 1.5rem;
  }

  & .input__icon > button {
    border: none;
  }
}

.input[value='']:not(:focus):not(:-webkit-autofill) + .label {
  font-size: 1rem;
  top: 50%;
  background-color: transparent;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  font-size: 0.75rem;
  background: none;
  padding: 0;
  font-weight: 600;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  // need to override styles from header-footer.css
  html &:focus {
    outline: 2px solid $color-grey-focus !important;
    outline-offset: 2px;
  }
}

.match {
  margin-top: 0.375rem;
  display: flex;

  &__holder {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-black;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    margin-right: 0.25rem;
  }
  & p {
    font-size: 0.75rem;
  }
}

.error {
  .input {
    box-shadow: 0 0 0 0.125rem $color-red-alert;
  }
  .label {
    color: $color-red-alert;
  }
  &:focus-within,
  &:hover {
    .input {
      box-shadow: 0 0 0 0.125rem $color-red-alert;
    }
  }
}

.text {
  margin-top: 0.375rem;
  & p {
    font-size: 0.75rem;
  }
}

.err {
  font-size: 0.75rem;
  margin-top: 0.375rem;
  display: flex;
  align-items: flex-start;
  & p {
    font-size: 0.75rem;
  }
  &__icon {
    margin-top: 0.1rem;
  }
  &__text {
    color: $color-red-alert;
    margin-left: 0.25rem;
    letter-spacing: 0;

    & a {
      color: $color-red-alert;
      font-weight: 500;
      font-size: 0.75rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
