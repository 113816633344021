@import "@christies/base.css/global";

.error {
  width: 100%;
  padding: 0.812rem 0.8745rem 0.812rem 0.937rem;
  background-color: $color-red-alert;
  border-radius: 0.125rem;
  border: 1px solid $color-red-alert;
  display: flex;
  text-align: left;

  i {
    margin: 0.275rem 0.5rem 0 0;
  }

  &__container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  & p {
    flex: 1;
    color: $color-white;
    font-size: 0.875rem;
    margin: 0.09rem 0;
    & a {
      color: $color-white;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}
