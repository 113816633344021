@import '@christies/base.css/global';

.nav {
  border-bottom: 1px solid #dfdfdf;
  &__items {
    gap: 2.5rem;
  }
}

.item {
  line-height: 1rem;
  letter-spacing: 0rem;

  & > a {
    padding-bottom: 0.25rem;

    border-bottom: 0.25rem solid transparent;
    transition: border-color $duration-shorter ease-in;

    &:hover,
    &.active {
      border-color: $color-black;
    }
  }
}
